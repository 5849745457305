export default {
  breakpoints: ["576px", "768px", "992px", "1200px", "1500px"],
  buttons: {
    primary: {
      bg: "primary",
      borderColor: "primary",
      borderRadius: "50px",
      borderStyle: "solid",
      borderWidth: "2px",
      color: "white",
      cursor: "pointer",
      display: "inline-block",
      fontFamily: "base",
      transition: "all 0.1s linear",
      whiteSpace: "nowrap",
      "&:hover": {
        bg: "transparent",
        color: "primary",
      },
    },
    secondary: {
      bg: "secondary",
      borderColor: "secondary",
      borderRadius: "67px",
      borderStyle: "solid",
      borderWidth: "2px",
      color: "white",
      cursor: "pointer",
      fontFamily: "base",
      transition: "all 0.1s linear",
      whiteSpace: "nowrap",
      "&:hover": {
        bg: "transparent",
        color: "secondary",
      },
    },
  },
  colors: {
    background: "#fff",
    black: "#000",
    blue: "#087CCA",
    lightGray: "#f3f3f3",
    primary: "#51bfa0",
    secondary: "#004242",
    text: "#000",
    white: "#fff",
  },
  fonts: {
    base: '"Eina02-Regular", sans-serif',
    heading: "inherit",
    text: {
      fontSize: [2, null, null, 4],
    },
  },
  fontSizes: [14, 16, 18, 20, 24, 28, 30, 38, 40, 42, 47, 50, 52, 68, 70],
  fontWeights: {
    heading: 400,
    normal: 400,
    medium: 500,
    bold: 700,
    heavy: 900,
  },
  forms: {
    input: {
      backgroundColor: "#f4f4f4",
      borderColor: "#f4f4f4",
      borderRadius: "14px",
      borderStyle: "solid",
      borderWidth: "3px",
      fontFamily: "base",
      fontSize: 2,
      outline: "none",
      px: "10px",
      py: "10px",
      transition: "all 0.1s linear",
      "&:focus": {
        borderColor: "primary",
      },
    },
    textarea: {
      backgroundColor: "#f4f4f4",
      borderColor: "#f4f4f4",
      borderRadius: "14px",
      borderStyle: "solid",
      borderWidth: "3px",
      fontFamily: "base",
      fontSize: 2,
      outline: "none",
      transition: "all 0.1s linear",
      px: "10px",
      py: "10px",
      "&:focus": {
        borderColor: "primary",
      },
    },
  },
  layout: {
    container: {
      maxWidth: ["100%", null, 720, 960, 1140],
      px: 5,
    },
    containerLg: {
      maxWidth: ["100%", null, 720, 960, 1140, 1440],
      px: 5,
    },
  },
  lineHeights: {
    heading: ["54px", null, null, "60px"],
  },
  text: {
    default: {
      fontSize: 2,
    },
    text: {
      fontSize: [2, null, null, 3],
    },
    textLG: {
      fontSize: [4, null, null, 5],
    },
    heading: {
      fontSize: [5, null, null, 8],
      fontWeight: "normal",
    },
    headingL: {
      fontSize: [5, null, null, 9],
      fontWeight: "normal",
    },
    headingXL: {
      fontSize: [9, null, null, 14],
      fontWeight: "normal",
    },
    headingLG: {
      fontSize: 5,
      lineHeight: ["30px", null, null, "40px"],
    },
    headingSM: {
      fontSize: 4,
    },
  },
  space: [
    0,
    4,
    8,
    10,
    12,
    15,
    16,
    18,
    20,
    24,
    30,
    32,
    40,
    48,
    56,
    64,
    72,
    80,
    128,
    256,
    512,
  ],
  styles: {
    root: {
      fontFamily: "base",
      "#___gatsby > div": {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        "> main": {
          flex: "1 auto",
        },
      },
    },
    h1: {
      margin: 0,
    },
    h2: {
      margin: 0,
      color: "green",
    },
    h3: {
      margin: 0,
    },
    h4: {
      margin: 0,
      color: "orange",
    },
    h5: {
      margin: 0,
    },
    h6: {
      margin: 0,
    },
    p: {
      margin: 0,
    },
    a: {
      display: "inline-block",
      margin: 0,
    },
  },
};
